import collect from 'collect.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'authCheck',
      'authUser',
      'countries',
      'country',
      'currencies',
      'currency',
      'favorites',
      'fid',
      'priceDisplay',
      'visited',
      'closed',
      'referral',
      'searches',
      'unit',
      'whiteLabel',
    ]),
    dir() {
      return (
        this.$i18n.locales.find((locale) => locale.code === this.$i18n.locale)
          .dir || 'ltr'
      )
    },
    colorMode() {
      return this.$colorMode.value
    },
    mapDriver() {
      return this.$config.mapDriver
    },
    isWhiteLabel() {
      return (
        this.$store.state.whiteLabel && this.$store.state.whiteLabel.white_label
      )
    },
    isAgent() {
      return this.authCheck && this.authUser.plan_id !== null
    },
    searchQuery() {
      return collect(this.$route.query)
        .only([
          'check_in',
          'check_out',
          'guests',
          'country',
          'currency',
          'session',
        ])
        .all()
    },
    joinLink() {
      return (
        (this.$i18n.locale === 'he'
          ? this.$config.joinHE
          : this.$config.joinEN) + (this.fid ? '?ref=' + this.fid : '')
      )
    },
  },

  methods: {
    ...mapActions([
      'addSearch',
      'setUnit',
      'setCountry',
      'setCurrency',
      'setPriceDisplay',
      'setVisited',
      'setClosed',
    ]),
    countryTitle(code) {
      const selected = this.countries.find((c) => c.code === code)
      if (selected) {
        return selected.translations[this.$i18n.locale]
          ? selected.translations[this.$i18n.locale].title
          : selected.name
      }
      return code
    },
    vat(hotel, city = null, country = null) {
      if (hotel.aggregator === 'innstant') {
        if (country === 'il' && city !== 'eilat') {
          return true
        }
      } else if (this.country === 'IL' || this.$i18n.locale === 'he') {
        if (
          country === 'il' &&
          city !== 'eilat' &&
          hotel.city_name !== 'Eilat'
        ) {
          return false
        }
      }

      return null
    },
    changUnit() {
      if (this.unit === 'm') {
        return this.setUnit('f')
      }

      return this.setUnit('m')
    },
    setAltImg(event) {
      event.target.src = '/images/placeholder.jpg'
    },
    formatStars(count) {
      if (count > 0 && count < 6) {
        return ['★', '★★', '★★★', '★★★★', '★★★★★'][Number(count) - 1]
      }

      return ''
    },
    sizeConverter(value, unit = null, base = 'f') {
      if (unit === null) {
        unit = this.unit
      }

      if (unit === base) {
        return Math.round(Number(value))
      }

      return unit === 'm'
        ? Math.round(Number(value * 0.09290304))
        : Math.round(Number(value / 0.09290304))
    },
    formatMoney(
      value,
      currencyCode,
      locale,
      hideSubunits,
      subunitsValue,
      subunitsToUnit,
      supplementalPrecision,
    ) {
      let ret = 0
      if (Number.isFinite(value)) {
        try {
          let numFormat = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currencyCode,
          })
          const options = numFormat.resolvedOptions()
          // const fraction_digits = options.minimumFractionDigits;
          if (subunitsToUnit > 1) {
            value = value / subunitsToUnit
          } else if (subunitsValue === true) {
            value = value / 10 ** options.minimumFractionDigits
          }
          if (hideSubunits === true) {
            numFormat = new Intl.NumberFormat(locale, {
              style: 'currency',
              currency: currencyCode,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
          } else if (supplementalPrecision > 0) {
            numFormat = new Intl.NumberFormat(locale, {
              style: 'currency',
              currency: currencyCode,
              minimumFractionDigits:
                options.minimumFractionDigits + supplementalPrecision,
              maximumFractionDigits:
                options.maximumFractionDigits + supplementalPrecision,
            })
          }
          ret = numFormat.format(value)
        } catch (err) {
          ret = err.message
        }
      } else {
        ret = '#NaN!'
      }
      return ret
    },
  },
}
