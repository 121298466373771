
import SunIcon from '@/assets/icons/sun.svg?inline'
import MoonIcon from '@/assets/icons/moon.svg?inline'
import SystemIcon from '@/assets/icons/system.svg?inline'

export default {
  components: {
    SunIcon,
    MoonIcon,
    SystemIcon,
  },
  props: {
    label: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      //
    }
  },
  methods: {
    setCurrentTheme() {
      this.$colorMode.preference =
        this.$colorMode.preference === 'system'
          ? 'light'
          : this.$colorMode.preference === 'light'
          ? 'dark'
          : 'system'
    },
  },
}
