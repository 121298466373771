
import InfoCircle from '@/assets/icons/fontawesome/duotone/info-circle.svg?inline'

export default {
  components: {
    InfoCircle,
  },
  props: {
    type: {
      type: String,
      default: 'info',
    },
  },
}
