
export default {
  props: {
    view: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    url() {
      return (
        this.$config.poweredDomain + (this.$i18n.locale === 'he' ? '/he' : '/')
      )
    },
    links() {
      if (this.isWhiteLabel) {
        return {
          Company: [
            {
              key: this.$i18n.t('Terms & Conditions'),
              to: this.localePath('terms-of-use'),
            },
            {
              key: this.$i18n.t('Privacy & Cookie Statement'),
              to: this.localePath('privacy-policy'),
            },
          ],
          Services: [
            { key: this.$i18n.t('Hotels'), to: this.localePath('hotels') },
            {
              key: this.$i18n.t('Hotels in Israel'),
              to: this.localePath('hotels-israel'),
            },
          ],
          Destinations: [],
        }
      } else {
        return {
          Company: [
            {
              key: this.$i18n.t('About'),
              href:
                'https://company.travelor.com/' + this.$i18n.t('lang/about'),
            },
            {
              key: this.$i18n.t('Testimonials'),
              href:
                'https://company.travelor.com/' +
                this.$i18n.t('lang/testimonials'),
            },
            {
              key: this.$i18n.t('FAQ'),
              href: 'https://company.travelor.com/' + this.$i18n.t('lang/faq'),
            },
            {
              key: this.$i18n.t('Contact Us'),
              href:
                'https://company.travelor.com/' + this.$i18n.t('lang/contact'),
            },
            {
              key: this.$i18n.t('Terms & Conditions'),
              to: this.localePath('terms-of-use'),
            },
            {
              key: this.$i18n.t('Privacy & Cookie Statement'),
              to: this.localePath('privacy-policy'),
            },
            // { key: this.$i18n.t('Terms & Conditions'), href: 'https://company.travelor.com/' + this.$i18n.t('lang/terms') },
            // { key: this.$i18n.t('Privacy & Cookie Statement'), href: 'https://company.travelor.com/' + this.$i18n.t('lang/privacy') },
            // { key: this.$i18n.t('Best Price Guaranteed'), href: 'https://company.travelor.com/' + this.$i18n.t('lang/best-price-guaranteed') }
          ],
          Services:
            this.$i18n.locale === 'he' || this.country === 'IL'
              ? [
                  {
                    key: this.$i18n.t('Hotels'),
                    to: this.localePath('hotels'),
                  },
                  {
                    key: this.$i18n.t('Hotels in Israel'),
                    to: this.localePath('hotels-israel'),
                  },
                  // { key: this.$i18n.t('Destinations', to: this.localePath('continents') },
                  {
                    key: this.$i18n.t('Flights'),
                    href:
                      this.$i18n.locale === 'he'
                        ? 'https://book.travelor.com/he/flights'
                        : 'https://book.travelor.com/flights',
                  },
                  // { key: this.$i18n.t('Car Rentals'), href: this.$i18n.locale === 'he' ? 'https://book.travelor.com/he/cars' : 'https://book.travelor.com/cars' },
                  {
                    key: this.$i18n.t('Tickets'),
                    href:
                      this.$i18n.locale === 'he'
                        ? 'https://book.travelor.com/he/event-tickets'
                        : 'https://book.travelor.com/event-tickets',
                  },
                  // { key: this.$i18n.t('Car Rentals'), href: 'https://www.rentalcars.com/?affiliateCode=travelor&adplat=company&preflang=' + this.$i18n.locale },
                  // { key: this.$i18n.t('Travel Insurance'), href: 'https://purchase.passportcard.co.il/?exT=VTJGc2RHVmtYMThPSEJMVDFpQit5ejUzMk9POXJCQmY4bmhBTUxFZjVGaz0=&t=VTJGc2RHVmtYMStJN2c1bHFHU3NGc1p5QW9BM3ljc2dVcHVvUFJiU2RQcXptakczM0o0bEZoZkthQTA2T1NPbFBEbDRvWmlYVWM2ZXRFOTFOeGZXd3QrbVlwSjlkckJKT0ttenpJdXl3VWJ2UWpiRDlmKzVvYzBvajlKQTNRbEVFVVB1b1kzeENCRmwvTGNIbGFvV3JXN1hNSkdvMm9sbU1MaS9FaXVwQ204PQ==&i=VTJGc2RHVmtYMTgrVTJRMEg2Z0FFRktOTUtuQUI1ZjhXMUFMWWVHYW0yamdDZC96KzZZd3BYTzIxcmRYbEhWakRHNU1seUVSaDNYRElwTUxGdnF6a2hwZVRYam04eHNmaXBIb2ZvRlFIT2ZmWklISkJzQkxaR0pVY1BHVHNTano=&AffiliateId=U2yZcALa4+BCLu5XhdExLg==' },
                  // { key: this.$i18n.t('Currency Exchange'), href: 'https://travelor.flymoney.com' },
                  {
                    key: this.$i18n.t('Join as an Agent'),
                    href: this.joinLink,
                  },
                ]
              : [
                  {
                    key: this.$i18n.t('Hotels'),
                    to: this.localePath('hotels'),
                  },
                  {
                    key: this.$i18n.t('Flights'),
                    href:
                      this.$i18n.locale === 'he'
                        ? 'https://book.travelor.com/he/flights'
                        : 'https://book.travelor.com/flights',
                  },
                  // { key: this.$i18n.t('Car Rentals'), href: this.$i18n.locale === 'he' ? 'https://book.travelor.com/he/cars' : 'https://book.travelor.com/cars' },
                  {
                    key: this.$i18n.t('Tickets'),
                    href:
                      this.$i18n.locale === 'he'
                        ? 'https://book.travelor.com/he/event-tickets'
                        : 'https://book.travelor.com/event-tickets',
                  },
                  // { key: this.$i18n.t('Car Rentals'), href: 'https://www.rentalcars.com/?affiliateCode=travelor&adplat=company&preflang=' + this.$i18n.locale },
                  {
                    key: this.$i18n.t('Join as an Agent'),
                    href: this.joinLink,
                  },
                ],
          Destinations:
            this.$i18n.locale === 'he'
              ? [
                  {
                    key: this.$i18n.t('Hotels in {destination}', {
                      destination: this.$i18n.t('Dubai'),
                    }),
                    to: this.localePath({ name: 'hotels-places-ae-dubai' }),
                  },
                  {
                    key: this.$i18n.t('Hotels in {destination}', {
                      destination: this.$i18n.t('Cancun'),
                    }),
                    to: this.localePath({ name: 'hotels-places-mx-cancun' }),
                  },
                  {
                    key: this.$i18n.t('Hotels in {destination}', {
                      destination: this.$i18n.t('Berlin'),
                    }),
                    to: this.localePath({ name: 'hotels-places-de-berlin' }),
                  },
                  {
                    key: this.$i18n.t('Hotels in {destination}', {
                      destination: this.$i18n.t('Barcelona'),
                    }),
                    to: this.localePath({ name: 'hotels-places-es-barcelona' }),
                  },
                  {
                    key: this.$i18n.t('Hotels in {destination}', {
                      destination: this.$i18n.t('Amsterdam'),
                    }),
                    to: this.localePath({ name: 'hotels-places-nl-amsterdam' }),
                  },
                ]
              : [],
        }
      }
    },
  },
}
