
export default {
  props: {
    value: {
      type: [String, Number, null],
      default: null,
    },
    floating: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onFocus(event) {
      this.$emit('focus')
    },
    onInput(event) {
      this.$emit('input', event.target.value)
    },
    onChange(event) {
      this.$emit('change', event.target.value)
    },
    onBlur() {
      this.$emit('blur')
    },
  },
}
