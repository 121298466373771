
import Wifi from '@/assets/icons/fontawesome/regular/wifi.svg?inline'
import WifiSlash from '@/assets/icons/fontawesome/regular/wifi-slash.svg?inline'

export default {
  components: {
    Wifi,
    WifiSlash,
  },
  data() {
    return {
      connection: false,
    }
  },
  watch: {
    '$nuxt.isOnline'(value) {
      if (value) {
        this.connection = true

        setTimeout(() => {
          this.connection = false
        }, 3000)
      } else {
        this.connection = true
      }
    },
  },
}
