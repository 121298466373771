
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    modalClass: {
      type: String,
      default:
        'sm:max-w-xl sm:w-full rounded-lg shadow-xl transform transition-all',
    },
  },
  watch: {
    show(value) {
      if (value) {
        return document.querySelector('body').classList.add('overflow-hidden')
      }

      document.querySelector('body').classList.remove('overflow-hidden')
    },
  },
  mounted() {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.close()
      }
    })
  },
  methods: {
    swipeHandler(direction) {
      if (screen.width > 639) {
        return
      }

      if (direction === 'bottom') {
        this.close()
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
