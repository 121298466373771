
import collect from 'collect.js'
import Check from '@/assets/icons/fontawesome/light/check.svg?inline'

export default {
  components: {
    Check,
  },

  props: {
    title: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modal: false,
      markup_amont: 0,
      markup_type: 'fix',
    }
  },

  methods: {
    setDisplay(display) {
      this.setPriceDisplay(display)

      this.modal = false
    },
    change(currencyCode) {
      const query = Object.assign({}, this.$route.query)
      query.currency = currencyCode

      this.$router
        .replace({
          query: collect(query).filter().all(),
        })
        .catch(() => {})

      this.setCurrency(currencyCode)

      this.modal = false
    },
  },
}
