
import UserCircle from '@/assets/icons/fontawesome/light/user-circle.svg?inline'
import ExternalLink from '@/assets/icons/fontawesome/light/external-link.svg?inline'

export default {
  components: {
    UserCircle,
    ExternalLink,
  },
  data() {
    return {
      errors: {},
      submiting: false,
      modalOpen: false,
      signUp: false,
      loginForm: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        remember: true,
        // currency: 'USD',
        // country: 'IL'
      },
    }
  },
  methods: {
    async register() {
      this.errors = {}
      this.submiting = true
      if (this.signUp) {
        await this.$axios.$get('/sanctum/csrf-cookie').then((res) => {
          this.$axios
            .$post('api/register', this.loginForm)
            .then((response) => {
              this.login()
            })
            .catch((error) => {
              this.submiting = false
              if (error.response && error.response.data) {
                this.errors = error.response.data.errors

                this.$toast.error(this.$i18n.t(error.response.data.message))
              }
            })
        })
      } else {
        this.login()
      }
    },
    async login(event) {
      this.errors = {}
      this.submiting = true

      try {
        await this.$auth.logout()
      } catch (error) {}

      await this.$auth
        .loginWith('cookie', { data: this.loginForm })
        .then(() => {
          this.$router.push({
            path: this.localePath(this.$route.path),
            query: this.$route.query,
          })

          this.modalOpen = false
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.errors = error.response.data.errors

            this.$toast.error(this.$i18n.t(error.response.data.message))
          }
        })

      this.submiting = false
    },

    async logout() {
      this.submiting = true

      await this.$auth.logout().then(() => {
        this.$router.push({
          path: this.localePath(this.$route.path),
          query: this.$route.query,
        })
      })

      this.modalOpen = false
      this.submiting = false
    },
  },
}
