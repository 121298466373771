import { render, staticRenderFns } from "./Currencies.vue?vue&type=template&id=ac2ff3cc&"
import script from "./Currencies.vue?vue&type=script&lang=js&"
export * from "./Currencies.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/vercel/path0/components/common/Button.vue').default,ListDivider: require('/vercel/path0/components/common/ListDivider.vue').default,ListGroup: require('/vercel/path0/components/common/ListGroup.vue').default,List: require('/vercel/path0/components/common/List.vue').default,ModalScreen: require('/vercel/path0/components/common/ModalScreen.vue').default})
