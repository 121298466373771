
import Check from '@/assets/icons/fontawesome/light/check.svg?inline'

export default {
  components: {
    Check,
  },

  props: {
    title: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modal: false,
    }
  },

  watch: {
    '$i18n.locale'() {
      this.modal = false
    },
  },
}
